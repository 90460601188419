import React, { Component } from 'react'

import painting from "../../assets/About/DH_studio_b.jpg"

export class About extends Component {
    render() {
        return (
            <div className="col-md-8 col-sm-12 pt-4 mr-auto" id="infoWrapper">

                <img src={painting} id="aboutImage" className="paintingImage pt-2" alt="Dan Hofstadter Studio" />
                    <div className="indent baskerville aboutText">As a teenager I studied at the Brooklyn Museum
                Art School and later at Columbia University. Moving to Paris, then
                Sweden, I had my first show in Stockholm with the dealer Eero Solin. I
                am always painting, and most recently I have had two shows, comprising
                more than 50 pieces altogether, at the Atlantic Works Gallery in Boston.</div>
                <div className="indent baskerville mb-5 aboutText">
                My current work, which can be large or small, lies between color abstraction and a kind of nature imagery. Color relationships come first, and shape themselves into areas or spots. The landscape is not depicted but transmuted. The fluidity of water, for instance, may reappear as an emphasis on tonal transitions. The fractured quality of rocks is recalled by irregular or jagged edges. I also make a great many drawings, most often landscapes, and these are frankly representational.
                                </div>
                <div className="indent baskerville aboutText">To see more work or to inquire about prices, contact me at dhworks.5012@gmail.com.</div>
                <div className="col-md-2 d-none d-sm-block"></div>


            </div>
        )
    }
}

export default About
