export const PortfolioImages = [
    require("./DH21-Iguazu;50x58;.jpeg"),
    require("./DH22-Irrawaddy;56x46;.jpeg"),
    require("./DH29-Oplontis;17.25x24.5;.jpg"),
    require("./DH27-Jade$s Blues;36x40;.jpeg"),
    require("./DH1-Kimono Study;50x58;.jpeg"),
    require("./DH23-Cloud Storage;50x50;.jpeg"),
    require("./DH26-Fragments on Red Ground;17x17.75;.jpg"),
    require("./DH9-Kazanka;18x18.5;.jpeg"),
    require("./DH12-Ex-Funk;7x8.5;.jpeg"),
    require("./DH34-Shapes on Blue Ground;7.5x7.5;.jpg"), 
    require("./DH7-Flipside 2017;11.5x18.5;.jpeg"),
    require("./DH28-Nilotic;38x40;.jpg"),
    require("./DH25-Forecast;38x36;.jpg"),
    require("./DH19-Procession;56x40;.jpeg"),
    require("./DH33-Rivers and Mountains;6.25x7;.jpg"),
    require("./DH35-Chalice;23x42;.jpg"),
    require("./DH 2024- Famous Men;52x42;.jpg"),
    require("./DH 2024- Tropical Landscape;32x50;.jpg")
]
export default PortfolioImages